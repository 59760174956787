import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrophy, faCheckCircle, faFileAlt } from '@fortawesome/free-solid-svg-icons'
import {Col, Row} from 'reactstrap';
import moment from 'moment';

const campaignFlow = (props) => {

    const shortenDate = (from, until) => {
        if (until) {
            return moment(from).format('DD.MM.') + ' - ' + moment(until).format('DD.MM.');
        }
        return moment(from).format('DD.MM.');
    };

    return (
        <Row>
            <Col xs="12" lg="6" className="my-2">
                <Row>
                    <Col xs="4" lg="12" className="d-flex align-items-center mb-2">
                        <FontAwesomeIcon
                            icon={faFileAlt}
                            size="3x"
                            className="fa-fw w-100 text-primary"/>
                    </Col>
                    <Col xs="7" lg="12" className="d-flex align-items-center">
                        <h3 className="text-primary text-left text-lg-center m-0 w-100">Bewerbung:<br />
                            ab sofort</h3>
                    </Col>
                </Row>
            </Col>
            <Col xs="12" lg="6" className="my-2">
                <Row>
                    <Col xs="4" lg="12" className="d-flex align-items-center mb-2">
                        <FontAwesomeIcon
                            icon={faTrophy}
                            size="3x"
                            className="fa-fw w-100 text-primary"/>
                    </Col>
                    <Col xs="7" lg="12" className="d-flex align-items-center">
                        <h3 className="text-primary text-left text-lg-center m-0 w-100">Abstimmung:<br />
                            {shortenDate(props.phases.voteStartFinale, props.phases.voteEnd)}</h3>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
};

export default campaignFlow;

