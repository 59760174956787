import React, {Fragment, useEffect, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faImage, faTimes} from "@fortawesome/free-solid-svg-icons";
import Gallery from '../../components/Gallery/Gallery.js';
import {
    Col,
    Card,
    CardImg,
    CardImgOverlay,
    Spinner,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Alert
} from 'reactstrap';
import classes from './NewMediaUpload.module.scss';
import axios from "../../axios-base";


function NewMediaUpload(props) {
    const [fileToDelete, setFileToDelete] = useState({});
    const [processedFiles, setProcessedFiles] = useState([]);
    const [unprocessedFiles, setUnprocessedFiles] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [isLoadingFiles, setIsLoadingFiles] = useState(false);
    const [serverUrls, setServerUrls] = useState([]);
    const [showNotice, setShowNotice] = useState(false);
    const [rejectedFiles, setRejectedFiles] = useState([]);
    const {getRootProps, getInputProps} = useDropzone({
        accept: {
            'image/png': ['.png'],
            'image/jpg': ['.jpg'],
            'image/jpeg': ['.jpeg']
        },
        maxSize: 10000000,
        maxFiles:9,
        onDropAccepted: acceptedFiles => {
            if(acceptedFiles.length + processedFiles.length <= 9){
                uploadFiles(acceptedFiles);
                // acceptedFiles.map(file => Object.assign(file, {
                //     preview: URL.createObjectURL(file)
                // }));
                displayUnprocessedFiles(acceptedFiles);
            } else {
                setShowNotice(true);
            }
        },
        onDropRejected: files => {
            setRejectedFiles(files);
            setShowNotice(true);
        }
    });

    const displayUnprocessedFiles = files => {
        files.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
        }));
        setUnprocessedFiles([...files]);
    }

    const uploadFiles = (filesToUpload) => {
        setIsLoadingFiles(true);
        //send the post request for files provided
        const arrayOfPostedFormData = filesToUpload.map((file) => {
            // create form
            const fd = new FormData();
            const axiosConfig = {
                onUploadProgress: (progressEvent) => {
                    return (progressEvent.loaded * 100) / progressEvent.total;
                }
            };
            fd.append('projectGallery', file);
            return axios.post('/media', fd, axiosConfig);
        });

        Promise.all(arrayOfPostedFormData).then((response) => {
            if (typeof response[0] !== 'undefined'){
            setProcessedFiles(prevState => [...prevState, ...filesToUpload]);
                let newArrayResponse = [];
                response.forEach(item => {
                    newArrayResponse.push( item.data.projectGallery[0]);
                });
                setServerUrls(prevState => [...prevState, ...newArrayResponse]);
                setIsLoadingFiles(false);
            }
        }).catch(error => {
            modalHandler();
        })
    }

    const removePromptHandler = file => {
        modalHandler();
        setFileToDelete(file);
    };

    const modalHandler = () => {
        // console.log("modalHandler Called!");
        setShowModal(!showModal);
    }

    const removeFileHandler = () => {
        // console.log("removeFileHandler Called!");
        if(fileToDelete){
            URL.revokeObjectURL(fileToDelete.preview);
            const indexOfProcessedFile = processedFiles.indexOf(fileToDelete);
            const indexOfUnProcessedFile = unprocessedFiles.indexOf(fileToDelete);
            //update the form urls
            setServerUrls(prevState => {
                console.log("Prev State of form urls: ", prevState);
                console.log("FILE URL found to be removed: ", prevState.filter(url => url === fileToDelete.url))
                return (prevState.filter(response => response.originalname !== fileToDelete.path));
            });
            if(indexOfProcessedFile >= 0){
                const newFiles = [...processedFiles];
                newFiles.splice(indexOfProcessedFile, 1);
                setProcessedFiles(newFiles);
            }
            if (indexOfUnProcessedFile > 0) {
                const newFiles = [...unprocessedFiles];
                newFiles.splice(indexOfUnProcessedFile, 1);
                setUnprocessedFiles(newFiles);
            }
        }
        setIsLoadingFiles(false);
        modalHandler();

    }

    const dismissAlert = () => {
        setShowNotice(false);
    }

    useEffect(() => {
        // Make sure to revoke the data uris to avoid memory leaks
        return () => {
            [...processedFiles, ...unprocessedFiles].map((file) => URL.revokeObjectURL(file.preview));

            // console.log("File URLs have been revoked");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setUnprocessedFiles([]);
        props.onUploadHandler(serverUrls);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [processedFiles, serverUrls]);

    const processedThumbs = processedFiles.map((file, index) => {
        return (
            <Col xs="12" s="6" md="6" lg="4" className="my-2" key={index}>
                <Card className={classes.MediaFile}>
                    <div key={file.name}>
                        <CardImg
                            className={classes.CardImg}
                            // className={classes.CardImg}
                            top
                            width="100%"
                            src={file.preview}
                            alt="Card image cap" />
                        <CardImgOverlay >
                            <div className={classes.deleteArea}>
                                <Button onClick={() => removePromptHandler(file)} disabled={isLoadingFiles} color="danger" className={classes.buttonDelete}><FontAwesomeIcon className={classes.icon} icon={faTimes} size="2x"/></Button>
                            </div>
                        </CardImgOverlay>
                    </div>
                </Card>
            </Col>
        );
    });

    const unProcessedThumbs = unprocessedFiles.map((file, index) => (
        <Col xs="12" s="6" md="6" lg="4" className="my-2" key={index}>
            <Card className={isLoadingFiles ? classes.MediaFile + " " + classes.Loading : classes.MediaFile}>
                <div key={file.name}>
                    <CardImg
                        className={classes.CardImg}
                        // className={classes.CardImg}
                        top
                        width="100%"
                        src={file.preview}
                        alt="Card image cap" />
                    <CardImgOverlay  className={classes.cardImageOverlay}>
                        <div className={classes.spinnerParentShow}>
                            <Spinner style={{ width: '3rem', height: '3rem' }} />{' '}
                        </div>
                        <div className={classes.deleteArea}>
                            <Button onClick={() => removePromptHandler(file)} disabled={isLoadingFiles} color="danger" className={classes.buttonDelete}><FontAwesomeIcon className={classes.icon} icon={faTimes} size="2x"/></Button>
                        </div>
                    </CardImgOverlay>

                </div>
            </Card>
        </Col>
    ));

    return (
        <Fragment>
            <Modal centered isOpen={showModal}>
                <ModalHeader toggle={modalHandler}>Entfernen</ModalHeader>
                <ModalBody>
                    Wollen Sie die Datei löschen?
                    <div>{fileToDelete.path}</div>
                </ModalBody>
                <ModalFooter>
                    <Button outline color="secondary"
                            onClick={modalHandler}>Abbrechen</Button>{' '}
                    <Button color="secondary" onClick={removeFileHandler}>Löschen</Button>
                </ModalFooter>
            </Modal>
            <section>
                <aside >
                    <Gallery isSignup>{processedThumbs}{ unProcessedThumbs}</Gallery>
                </aside>
                <Alert isOpen={showNotice} toggle={dismissAlert} color="warning">
                    Einige Bilder konnten nicht hochgeladen werden. Bitte überprüfen Sie Dateityp, -größe und -anzahl (max. 9 Bilder). <strong> Sie können Ihre Bewerbung trotzdem absenden</strong>, aber die folgenden Bilder werden ausgelassen:
                    <ul>
                        {rejectedFiles.map((file, index )=> {
                            return (<li key={index}>{file.file.name}</li>);
                        })}
                    </ul>
                </Alert>
                <div {...getRootProps({className: classes.Dropzone + ' ' + (isLoadingFiles ? classes.dropzoneDisabled : classes.dropzoneEnabled)})}>
                    {!isLoadingFiles && <input {...getInputProps()} />}
                    {/*<input {...getInputProps()} />*/}
                    <FontAwesomeIcon icon={faImage} className="px-1" size="2x"/>
                    {isLoadingFiles ? "Bitte warten Sie bis die Bilder hochgeladen wurden" : "Bilder hinzufügen (max. Größe 10MB je Bild)"}
                </div>

            </section>
        </Fragment>
    );
}

export default NewMediaUpload;