import React from 'react';
import NavigationItem from '../NavigationItem/NavigationItem';
import {Nav, NavLink} from 'reactstrap';
import { NavLink as RRNavLink } from 'react-router-dom';
import MenuItemPlaceholder from "../../UI/Placeholder/MenuItemPlaceholder/MenuItemPlaceholder";

const footerMenu = (props) => {
    let secondaryNavigation = (
        <React.Fragment>
            <li style={{width: '140px', padding: '8px'}}><MenuItemPlaceholder/></li>
            <li style={{width: '140px', padding: '8px'}}><MenuItemPlaceholder/></li>
            <li style={{width: '140px', padding: '8px'}}><MenuItemPlaceholder/></li>
            <li style={{width: '140px', padding: '8px'}}><MenuItemPlaceholder/></li>
        </React.Fragment>
    );
    if(props.secondaryNavigation) {
        secondaryNavigation = props.secondaryNavigation.map((menuItem, index) => {
            return <NavigationItem key={index} type="header"><NavLink to={menuItem.linkTarget + '/'} tag={RRNavLink} exact className={`anl_footer--menu-lnk-${index+1}`}>{menuItem.text}</NavLink></NavigationItem>
        })
    }

    return (
        <div>
            <Nav className="ms-auto flex-column flex-lg-row justify-content-end">
                {secondaryNavigation}
            </Nav>
        </div>
    )
};

export default footerMenu;
