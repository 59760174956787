import React, {Component} from 'react';
import ReCaptcha from 'reaptcha';
import Fingerprint2 from 'fingerprintjs2';
import {Button, Col} from 'reactstrap';
import axios from '../../axios-base';


class FingerprintVote extends Component {
    constructor(props) {
        super(props);
        this.captcha = null;
        this.state = {
            loadedPost: null,
            fingerprint: '',
            responseToken: '',
            votingButton: 'Initialisiere...',
            votingReady: false,
        };
    }

    componentDidMount() {
        const options = {};
        let fingerprint;
        // Todo: Move browser fingerprint getter before or after reCAPTCHA verification to only fingerprint voters
        Fingerprint2.getPromise(options).then((components) => {
            const values = components.map(({ value }) => value).join("");
            fingerprint = Fingerprint2.x64hash128(values, 31);
            this.setState({ fingerprint });
        });
    }

    onVoteHandler = () => {
        this.captcha.execute();
    };


    // On reCAPTCHA verify
    onVerify = (responseToken) => {
        const { fingerprint } = this.state;
        const { id } = this.props.match.params;
        axios.patch(`/projects/${id}/vote`, {
            fingerprint,
            responseToken,
        })
        .then((response) => {
            if (response !== undefined) {
                let updatedPost = {...this.state.loadedPost};
                updatedPost.voteCount = response.data.voteCount;

                this.setState({
                    loadedPost: updatedPost,
                    votingReady: false,
                    votingButton: 'Vielen Dank für Ihre Stimme!'
                });
                this.props.history.push('/stimme-bestaetigt');
            } else {
                this.setState({
                    votingReady: false,
                    votingButton: 'Sie haben bereits abgestimmt!'
                });
                throw new Error('Sie haben bereits abgestimmt!');
            }
        })
        .catch(error => {
            console.log(error);
        });
        this.captcha.reset();
    };

    onLoad = () => {
        this.setState({
            votingButton: 'Jetzt abstimmen!',
            votingReady: true
        });
    };

    render() {
        return(
            <Col xs="12" sm="6" lg="12">
                <ReCaptcha
                    ref={e => (this.captcha = e)}
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY}
                    onVerify={this.onVerify}
                    onError={this.onError}
                    onLoad={this.onLoad}
                    size="invisible"
                />
                <Button
                    className={this.state.votingReady ? 'anl_fp--vote' : null}
                    block
                    disabled={!this.state.votingReady}
                    color="primary"
                    onClick={this.onVoteHandler}
                >
                    {this.state.votingButton}
                </Button>
                <p className="text-black-50 small my-2">Diese Seite wird durch reCAPTCHA geschützt.
                    Es gelten die <a class="anl_recaptcha--privacy" target="_blank" rel="noopener noreferrer" href="https://policies.google.com/privacy">Daten&shy;schutz&shy;erklärung</a> und die <a class="anl_recaptcha--terms" target="_blank" rel="noopener noreferrer" href="https://policies.google.com/terms">Nutzungs&shy;bedingungen</a> von Google.</p>
            </Col>
        );
    }
}


export default FingerprintVote;
