import React from 'react';
import ContentLoader from "react-content-loader"

const CallToActionPlaceholder = (props) => (
    <ContentLoader
        speed={2}
        width={384}
        height={188}
        viewBox="0 0 384 188"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...props}
    >
        <rect x="0" y="0" rx="4" ry="4" width="320" height="16" />
        <rect x="0" y="38" rx="4" ry="4" width="384" height="11" />
        <rect x="0" y="90" rx="4" ry="4" width="384" height="11" />
        <rect x="0" y="64" rx="0" ry="0" width="384" height="11" />
        <rect x="0" y="116" rx="0" ry="0" width="160" height="11" />
        <rect x="0" y="150" rx="4" ry="4" width="384" height="38" />
    </ContentLoader>
);

export default CallToActionPlaceholder;
