import React from 'react';
import ContentLoader from "react-content-loader"

const MenuItemPlaceholder = props => (
    <ContentLoader
        speed={2}
        width={140}
        height={24}
        viewBox="0 0 140 11"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...props}
    >
        <rect x="0" y="0" rx="4" ry="4" width="140" height="11" />
    </ContentLoader>
);

export default MenuItemPlaceholder;
