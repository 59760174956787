import React from 'react';
import {Col} from 'reactstrap';

const VotePlaceholder = ({text}) => {

    return (
      <Col>
          <div className="rounded px-3 px-sm-4 py-3 py-sm-5">
              <h1 className="text-center">{text}</h1>
          </div>
      </Col>
    );
}

export default VotePlaceholder;