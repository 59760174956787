import React from 'react';
import ContentLoader from "react-content-loader"

const profilePlaceholder = props => (
    <ContentLoader
        height={500}
        width={300}
        speed={2}
        primarycolor="#f3f3f3"
        secondarycolor="#ecebeb"
        {...props}
    >
        <rect x="100" y="15" rx="4" ry="4" width="80" height="6.4" />
        <rect x="100" y="40" rx="3" ry="3" width="80" height="6.4" />
        <rect x="200" y="15" rx="4" ry="4" width="80" height="6.4" />
        <rect x="200" y="40" rx="3" ry="3" width="80" height="6.4" />
        <circle cx="50" cy="50" r="35" />
        <rect x="15" y="110" rx="4" ry="4" width="270" height="6.4" />
        <rect x="15" y="130" rx="4" ry="4" width="270" height="6.4" />
        <rect x="15" y="150" rx="4" ry="4" width="270" height="6.4" />
        <rect x="15" y="170" rx="4" ry="4" width="270" height="6.4" />
        <rect x="15" y="190" rx="4" ry="4" width="270" height="6.4" />
        <rect x="15" y="210" rx="4" ry="4" width="270" height="6.4" />
        <rect x="15" y="250" rx="4" ry="4" width="270" height="38" />
        <rect x="15" y="310" rx="4" ry="4" width="42" height="42" />
        <rect x="70" y="310" rx="4" ry="4" width="42" height="42" />
        <rect x="125" y="310" rx="4" ry="4" width="42" height="42" />
        <rect x="180" y="310" rx="4" ry="4" width="42" height="42" />
        <rect x="0" y="370" rx="0" ry="0" width="320" height="210" />
    </ContentLoader>
);

export default profilePlaceholder;
