import React, {Component} from 'react';
import classes from './Page.module.scss';
import {createMarkup} from "../../shared/utility";
import { Col } from 'reactstrap';
import { dom, library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import {Helmet} from "react-helmet";
import {sendVirtualPageView} from "../../shared/utility";

class Page extends Component{
    componentDidMount() {
        library.add(fas);
        /**
         * Use of dom.watch() method to convert any of the icons provided by API to SVG tags
         * To avoid unnecessary css cruft in <head> dom.watch() is confined to this component's
         * function.
         *
         * The watch() method expects the to-be-watched DOM node to be present before method
         * invocation. Usually the parent component should contain said DOM node
         *
         * @param {Object} autoReplaceSvgRoot watch for a particular element
         * @param {Object} observeMutationsRoot watch for a particular element
         * https://fontawesome.com/how-to-use/with-the-api/methods/dom-watch
         */
        library.add(fas);
        dom.watch({
            autoReplaceSvgRoot: document.getElementById('dom-watch'),
            observeMutationsRoot: document.getElementById('dom-watch')
        });
        sendVirtualPageView(this.props.pageTitle)
    }

    render() {
        return (
            <React.Fragment>
            <Helmet>
                <title>{`${process.env.REACT_APP_PROJECT_TITLE}: ${this.props.pageTitle}`}</title>
            </Helmet>
            <Col className="my-3">
                <article id="page-content"><h1>{this.props.pageTitle}</h1>
                    <div dangerouslySetInnerHTML={createMarkup(this.props.pageBody)} className={classes.Page}/>
                </article>
            </Col>
            </React.Fragment>
        );
    }
}

export default Page;
