import React from 'react';

const  responsiveImageSet = (props) => (
    <div>
        <picture>
            <source
                media="(max-width: 767px)"
                srcSet={props.imageXs} />

            <source
                media="(min-width: 768px)"
                srcSet={props.imageLg} />

            <img
                src={props.imageLg}
                className="img-fluid"
                alt="Key Visual" />
        </picture>

    </div>
);

export default responsiveImageSet;
