import React from 'react';
import { Row, Col } from 'reactstrap';
import ContentLoader from "react-content-loader"

// Todo: Match visual placeholder style with final project list item style
const projectListItemPlaceholder = props => {
    let windowSize = window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
    let mobileView = windowSize < 575;

    if(mobileView){
        return(
            <Row>
                <Col>
                    <ContentLoader
                        height={90}
                        width={320}
                        speed={2}
                        primarycolor="#f3f3f3"
                        secondarycolor="#ecebeb"
                        {...props}
                    >
                        <rect x="95" y="20" rx="3" ry="3" width="200" height="10" />
                        <rect x="95" y="40" rx="3" ry="3" width="200" height="6.4" />
                        <rect x="15" y="0" rx="0" ry="0" width="70" height="80" />
                    </ContentLoader>
                </Col>
            </Row>
        )
    } else {
        return(
            <Row>
                <ContentLoader
                    height={80}
                    width={1100}
                    speed={2}
                    primarycolor="#f3f3f3"
                    secondarycolor="#ecebeb"
                    {...props}
                >
                    <rect x="300" y="10" rx="10" ry="10" width="780" height="15"/>
                    <rect x="300" y="40" rx="5" ry="5" width="780" height="10"/>
                    <rect x="15" y="0" rx="0" ry="0" width="275" height="68"/>
                </ContentLoader>
            </Row>
        )
    }

};

export default projectListItemPlaceholder;