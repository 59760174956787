import React from 'react';
import classes from './ProjectLogo.module.scss';

const projectLogo = (props) => {
    return (
        <div className={classes.ProjectLogo}>
            <img
                src={props.src}
                alt={props.alt} />
        </div>
    );
};

export default projectLogo;