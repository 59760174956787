import React from 'react';
import { Col } from 'reactstrap';

const projectListCounter = (props) => {
    let message = '';
    if(props.categories) {
        if(props.projectsCount > 0) {
            message = `In dieser Kategorie gibt es ${props.projectsCount} Teilnehmer!`;
        } else {
            message = `Keine Teilnehmer! Versuchen Sie eine andere Auswahl`;
        }
    } else {
        message = `Wir freuen uns über ${props.projectsCount} Teilnehmer!`;
    }

    return (
        <Col>
            <h3>{message}</h3>
        </Col>
    );
};

export default projectListCounter;
